// extracted by mini-css-extract-plugin
export var container = "post-box-module--container--I2V8l";
export var content = "post-box-module--content--HsOFi";
export var img = "post-box-module--img--hFCxf";
export var innerContainer = "post-box-module--innerContainer--RDY4l";
export var lead = "post-box-module--lead--WAUXn";
export var link = "post-box-module--link--c65To";
export var rectangleImage = "post-box-module--rectangle-image--dsYVJ";
export var squareImage = "post-box-module--square-image--PeEN4";
export var subtitle = "post-box-module--subtitle--7zwzc";
export var title = "post-box-module--title--wmBLz";