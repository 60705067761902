import React from 'react';
import { graphql } from 'gatsby';
import { useI18next } from '../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import Moment from 'react-moment';
import { getImage } from 'gatsby-plugin-image';

import {
    layout,
    breadcrumbsBox,
    breadcrumbsList,
    header,
    headerBox,
    leadBox,
    body,
    avatarContainer,
    avatar,
    avatarRatio,
    related,
    tagsBox,
    date,
    authorName,
    markdown,
    footerBox,
    share,
    products,
    productsTitle,
} from './post.module.scss';
import { IPost } from '../models/post.model';
import { IBanner } from '../models/banner.model';
import { IAuthor } from '../models/author.model';
import { ISitePage } from '../models/site-page.model';
import { TProvider } from '../config/embed-providers';
import { useTranslation } from '../hooks/use-translation';
import { getMediaWithRelation } from '../utils/get-relation';
import { getBreadcrumbItem } from '../utils/get-breadcrumbs';
import { config } from '../config';

import SEO from '../components/seo';
import MainLayout from '../layouts/main-layout';
import Markdown from '../components/hoc/markdown';
import RelatedList from '../components/organisms/related-list';
import TagList from '../components/molecules/tag-list';
import Embed from '../components/hoc/emded';
import Avatar from '../components/atoms/avatar';
import PostBox from '../components/atoms/post-box';
import Title from '../components/atoms/title';
import ShareButtons from '../components/molecules/share-buttons';
import ProductSlider from '../components/organisms/product-slider';

const { relations, translationKeys } = config;

const providers: TProvider[] = ['instagram'];

interface IPostProps {
    readonly data: {
        post: IPost;
        author: IAuthor;
        blogPage: ISitePage;
    };
}

const Post: React.FC<IPostProps> = ({ data }) => {
    const { t, language } = useI18next();
    const { post, author, blogPage } = data || {};
    const { title, lead, media } = useTranslation(post, translationKeys.post);
    const { tags, box1, box2, box3, contentWithTableOfContents, relatedProducts, relatedPosts } =
        post || {};

    const { content, content2, content3 } =
        (contentWithTableOfContents && contentWithTableOfContents[language]) || {};

    const mediaItem = author && getMediaWithRelation(author.media, relations.mainImage);
    const image = mediaItem?.remoteImage && getImage(mediaItem.remoteImage);

    const { firstName, lastName } = useTranslation(author, translationKeys.author, true);

    const banner: IBanner = {
        media: post.media,
        translations: {
            en: {
                title: post.translations.en?.title,
            },
            pl: {
                title: post.translations.pl?.title,
            },
        },
        contentAlign: 'center',
        horizontalStart: 'center',
        verticalStartMobile: 'center',
        contentWidth: 55,
    };

    return (
        <>
            <SEO
                title={title}
                description={lead}
                image={getMediaWithRelation(media, relations.mainImage)?.url}
            />
            <Embed useProviders={providers} />
            <MainLayout
                className={layout}
                bannersData={
                    banner && {
                        banner,
                        titleData: { Tag: 'h1' },
                        hasTextShadow: true,
                    }
                }
                breadcrumbsConfig={{
                    items: [getBreadcrumbItem(blogPage.context), getBreadcrumbItem(post, 'title')],
                    className: breadcrumbsBox,
                    listClassName: breadcrumbsList,
                }}
            >
                <div className={header}>
                    <div className={headerBox}>
                        {tags && tags.length > 0 && (
                            <TagList
                                className={tagsBox}
                                tags={tags}
                                isSmall={true}
                                asLinks={true}
                            />
                        )}
                        <Title Tag={'p'} size={'medium'}>
                            {title}
                        </Title>
                        <Moment className={date} locale={language} unix={true} format="DD.MM.YYYY">
                            {post.publishedAt}
                        </Moment>
                        {author && (
                            <>
                                <p className={authorName}>
                                    {firstName} {lastName}
                                </p>
                                <div className={avatarContainer}>
                                    <Avatar
                                        image={image}
                                        className={avatar}
                                        ratioClass={avatarRatio}
                                    />
                                </div>
                            </>
                        )}

                        <p className={leadBox}>{lead}</p>
                    </div>
                </div>
                <div className={body}>
                    {content && (
                        <Markdown className={markdown} styleType="post">
                            {content}
                        </Markdown>
                    )}
                    {box1 && <PostBox box={box1} />}
                    {content2 && (
                        <Markdown className={markdown} styleType="post">
                            {content2}
                        </Markdown>
                    )}
                    {box2 && <PostBox box={box2} />}
                    {content3 && (
                        <Markdown className={markdown} styleType="post">
                            {content3}
                        </Markdown>
                    )}
                    {box3 && <PostBox box={box3} />}
                </div>
                <div className={footerBox}>
                    <ShareButtons className={share} />
                    {tags && tags.length > 0 && (
                        <TagList className={tagsBox} tags={tags} isSmall={true} asLinks={true} />
                    )}
                </div>
                {relatedPosts.length > 0 && (
                    <RelatedList
                        className={related}
                        title={t('related.articles')}
                        posts={relatedPosts}
                        titleTag={'h3'}
                        listTag={'h4'}
                    />
                )}
                {relatedProducts.length > 0 && (
                    <ProductSlider
                        sliderSettings={sliderSettings}
                        className={products}
                        titleClassName={productsTitle}
                        products={relatedProducts}
                        title={t('related.products')}
                    />
                )}
            </MainLayout>
        </>
    );
};

const sliderSettings = {
    navigation: false,
    autoplay: { delay: 5000, disableOnInteraction: false },
    pagination: false,
    speed: 500,
    slidesPerView: 1,
    loop: true,
    breakpoints: {
        700: {
            slidesPerView: 2,
        },
        850: {
            slidesPerView: 3,
        },
        1024: {
            slidesPerView: 4,
        },
    },
};

export const query = graphql`
    query ($language: String!, $articleId: Int!, $profileId: Int!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        post(articleId: { eq: $articleId }) {
            ...postFields
        }

        author(profileId: { eq: $profileId }) {
            ...authorFields
        }

        blogPage: sitePage(context: { pageKey: { eq: "blog" } }) {
            ...sitePageFields
        }
    }
`;

export default Post;
