import React from 'react';

import { container, header, list } from './related-list.module.scss';
import { grid } from '../../styles/grid.module.scss';
import { IPostCard } from '../../models/post.model';

import PostsList from './posts-list';
import Title from '../atoms/title';

interface IRelatedListProps {
    className?: string;
    title?: string;
    titleTag?: React.ElementType;
    listTag?: React.ElementType;
    posts: IPostCard[];
}

const RelatedList: React.FC<IRelatedListProps> = ({
    className = '',
    title = '',
    titleTag,
    listTag,
    posts,
}) => {
    return (
        <div className={`${grid} ${container} ${className}`}>
            {title && (
                <Title className={header} Tag={titleTag}>
                    {title}
                </Title>
            )}
            <PostsList className={list} posts={posts} titleTag={listTag} isSmallCard={true} />
        </div>
    );
};

export default RelatedList;
