import React from 'react';

import { tagBox, small, active, canHover } from './tag.module.scss';
import { config } from '../../config';
import { ITag } from '../../models/tag.model';
import {
    getInteractiveElementProps,
    getInteractiveElementTag,
} from '../../utils/get-interactive-element-tag';
import { useTranslation } from '../../hooks/use-translation';

const { translationKeys } = config;

interface ITagProps {
    className?: string;
    tag: ITag;
    asLink?: boolean;
    isSmall?: boolean;
    isActive?: boolean;
}

const Tag: React.FC<ITagProps> = ({
    className = '',
    tag,
    asLink = false,
    isSmall = false,
    isActive = false,
}) => {
    const { slug, tagId } = tag;
    const { name } = useTranslation(tag, translationKeys.tag);

    const to = tagId ? `${slug}-${tagId}` : slug;

    const Element = getInteractiveElementTag(asLink ? 'link' : 'element');
    const elementProps = getInteractiveElementProps(asLink ? 'link' : 'element', {
        to: to,
        activeClassName: active,
    });

    return (
        <Element
            className={`
                ${tagBox} 
                ${className} 
                ${asLink ? canHover : ''}
                ${isSmall ? small : ''}
                ${isActive && asLink ? active : ''}
            `}
            {...elementProps}
        >
            {name}
        </Element>
    );
};

export default Tag;
