// extracted by mini-css-extract-plugin
export var authorName = "post-module--author-name--SbSdV";
export var avatar = "post-module--avatar--MI1wb";
export var avatarContainer = "post-module--avatar-container--WODN6";
export var avatarRatio = "post-module--avatar-ratio--zOoMn";
export var body = "post-module--body--+a2fz";
export var breadcrumbsList = "post-module--breadcrumbs-list--BBGPY";
export var date = "post-module--date--AkJ5B";
export var footerBox = "post-module--footer-box--+uupQ";
export var header = "post-module--header--PvAqW";
export var headerBox = "post-module--header-box--nGU2F";
export var layout = "post-module--layout--3pHBu";
export var leadBox = "post-module--lead-box--oqp2f";
export var markdown = "post-module--markdown--o91q7";
export var meta = "post-module--meta--ZtX6a";
export var products = "post-module--products--zC8v4";
export var productsTitle = "post-module--products-title--F77NI";
export var related = "post-module--related--Y+NL8";
export var share = "post-module--share--6SMit";
export var tagsBox = "post-module--tags-box--xUwLq";