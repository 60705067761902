import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useLocation } from '@reach/router';

import {
    container,
    content,
    img,
    innerContainer,
    lead as leadClass,
    link as linkClass,
    rectangleImage,
    squareImage,
    subtitle as subtitleClass,
    title as titleClass,
} from './post-box.module.scss';
import { EViewType, IPostBox } from '../../models/post.model';
import { config } from '../../config';
import { useTranslation } from '../../hooks/use-translation';
import { getMediaWithRelation } from '../../utils/get-relation';
import { isInternalLink } from '../../utils/is-internal-link';
import { getInteractiveElementTag } from '../../utils/get-interactive-element';
import ArrowRight from '../../assets/images/svg/arrow-right.svg';

const { relations, translationKeys } = config;

interface IPostBoxProps {
    className?: string;
    box: IPostBox;
}

const PostBox: React.FC<IPostBoxProps> = ({ className = '', box }) => {
    const { hostname } = useLocation();
    const { title, subtitle, lead, url, buttonText } = useTranslation(box, translationKeys.postBox);
    const { viewType } = box;
    const image = getMediaWithRelation(box.media, relations.mainImage);
    const linkType = isInternalLink(url, hostname);
    const TagElement = getInteractiveElementTag(linkType ? 'link' : 'externalLink');
    const elementClassName = `${getTypeClass(viewType)} ${innerContainer} ${className}`;
    const linkSettings = linkType
        ? {
              to: url,
          }
        : {
              href: url,
              rel: 'noreferrer noopener',
              target: '_blank',
          };

    return (
        <div className={container}>
            <TagElement {...linkSettings} className={elementClassName}>
                {image?.remoteImage && getImage(image.remoteImage) && (
                    <GatsbyImage
                        className={img}
                        image={getImage(image.remoteImage)}
                        alt={image.alt || ''}
                        objectFit={viewType === EViewType.SquareImage ? 'cover' : 'contain'}
                        objectPosition={viewType === EViewType.SquareImage ? 'center' : 'top'}
                    />
                )}
                <div className={content}>
                    {title && <h3 className={titleClass}>{title}</h3>}
                    {subtitle && <p className={subtitleClass}>{subtitle}</p>}
                    {lead && <p className={leadClass}>{lead}</p>}
                    {buttonText && (
                        <span className={linkClass}>
                            {buttonText} <ArrowRight />
                        </span>
                    )}
                </div>
            </TagElement>
        </div>
    );
};

function getTypeClass(viewType: IPostBox['viewType']): string {
    switch (viewType) {
        case EViewType.SquareImage:
            return squareImage;
        case EViewType.RectangleImage:
            return rectangleImage;
    }
}

export default PostBox;
