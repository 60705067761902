import React, { useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { list, centered, item } from './tag-list.module.scss';
import { ITag } from '../../models/tag.model';

import Tag from '../atoms/tag';

interface ITagListProps {
    className?: string;
    defaultTag?: ITag;
    tags: ITag[];
    asLinks?: boolean;
    isSmall?: boolean;
    isCentered?: boolean;
}

const TagList: React.FC<ITagListProps> = ({
    className = '',
    defaultTag,
    tags,
    asLinks = false,
    isSmall = false,
    isCentered = false,
}) => {
    const [activeTag, setActiveTag] = useState<ITag | 'default'>();
    const { language } = useI18next();
    const { pathname } = useLocation();

    useEffect(() => {
        const newActiveTag = tags.find((tag) => {
            if (tag.translations[language]) return;
            return pathname.includes(`/${tag.translations[language].slug}/`);
        });
        setActiveTag(newActiveTag);
    }, [pathname]);

    return (
        <ul className={`${list} ${className} ${isCentered ? centered : ''}`}>
            {defaultTag && (
                <li key={`tag-default`} className={item}>
                    <Tag
                        tag={defaultTag}
                        asLink={asLinks}
                        isSmall={isSmall}
                        isActive={activeTag === 'default'}
                    />
                </li>
            )}
            {tags.map((tag, index) => {
                return (
                    <li key={`tag-${index}`} className={item}>
                        <Tag
                            tag={tag}
                            asLink={asLinks}
                            isSmall={isSmall}
                            isActive={activeTag !== 'default' && activeTag?.tagId === tag.tagId}
                        />
                    </li>
                );
            })}
        </ul>
    );
};

export default TagList;
