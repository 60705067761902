/*
 * Add more providers if you need by adding provider name in TProvider and { name: url } in embedProviders.
 */
import { TAbsoluteUrl } from "../models/url.model";

export type TProvider = 'instagram' | 'twitter';

export interface IEmbedProvider {
    src: TAbsoluteUrl;
    onLoad?(): void;
}

export type TEmbedProviderMap = Record<TProvider, IEmbedProvider>;

const embedProviders: TEmbedProviderMap = {
    instagram: {
        src: 'https://www.instagram.com/embed.js',
        onLoad() {
            // @ts-ignore
            if (!instgrm) return;
            // @ts-ignore
            instgrm.Embeds.process()
        }
    },
    twitter: {
        src: 'https://platform.twitter.com/widgets.js',
    },
};

export default embedProviders;
