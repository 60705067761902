import React from 'react';
import {
    FacebookShareButton,
    LinkedinShareButton,
    TelegramShareButton,
    FacebookMessengerShareButton,
} from 'react-share';
import { useLocation } from '@reach/router';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import * as styles from './share-buttons.module.scss';
import FacebookIcon from '../../assets/images/svg/facebook.svg';
import MessengerIcon from '../../assets/images/svg/messenger.svg';
import LinkedinIcon from '../../assets/images/svg/linkedin.svg';
import TelegramIcon from '../../assets/images/svg/telegram.svg';

import Title from '../atoms/title';

type TSocialMedia = 'facebook' | 'messenger' | 'linkedin' | 'telegram';

export interface IShareButtonsProps {
    href?: string;
    className?: string;
}

export default function ShareButtons({ href, className }: IShareButtonsProps) {
    const { t } = useI18next();
    const { href: defaultHref } = useLocation();
    const socialMedia = {
        facebook: {
            button: FacebookShareButton,
            icon: FacebookIcon,
        },
        messenger: {
            button: FacebookMessengerShareButton,
            icon: MessengerIcon,
        },
        linkedin: {
            button: LinkedinShareButton,
            icon: LinkedinIcon,
        },
        telegram: {
            button: TelegramShareButton,
            icon: TelegramIcon,
        },
    };
    const socialMediaKeys = Object.keys(socialMedia) as TSocialMedia[];

    return (
        <div className={`${styles.shareButtons} ${className}`}>
            <Title className={styles.title}>
                <span>{t('share.title')}</span>
            </Title>
            <div className={styles.container}>
                {socialMediaKeys.map((key: TSocialMedia, index) => {
                    const Button = socialMedia[key].button;
                    const Icon = socialMedia[key].icon;

                    return (
                        <Button
                            key={`share-button-${index}`}
                            url={href ? href : defaultHref}
                            className={`${styles.button} ${styles[key]}`}
                        >
                            <Icon />
                        </Button>
                    );
                })}
            </div>
        </div>
    );
}
