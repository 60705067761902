import { IMedia } from './media.model';
import { ITag } from './tag.model';
import { IProduct } from './product.model';

export enum EViewType {
    SquareImage = 0,
    RectangleImage = 1,
}

export interface IPostBoxTranslation {
    title: string;
    subtitle: string;
    lead: string;
    url: string;
    buttonText: string;
}

export interface IPostBox {
    articleId: number;
    slug: string;
    viewType: EViewType;
    translations: {
        [key: string]: IPostBoxTranslation;
    };
    media: IMedia[];
}

export interface IPostTranslation {
    title: string;
    lead?: string;
    content?: string;
    content2?: string;
    content3?: string;
}

export interface IPostCard {
    slug: string;
    publishedAt: number;
    media: IMedia[];
    tags: ITag[];
    translations: {
        [key: string]: IPostTranslation;
    };
}

export interface IPostContentWithTableOfContentsTranslation {
    content?: string;
    content2?: string;
    content3?: string;
}

export interface IPost {
    articleId: number;
    slug: string;
    contentWithTableOfContents?: {
        [key: string]: IPostContentWithTableOfContentsTranslation;
    };
    publishedAt: number;
    media: IMedia[];
    relatedPosts: IPostCard[];
    relatedProducts: IProduct[];
    tags: ITag[];
    translations: {
        [key: string]: IPostTranslation;
    };
    box1: IPostBox;
    box2: IPostBox;
    box3: IPostBox;
}
