import React, { useState, useEffect } from 'react';
import { Helmet, HelmetTags } from 'react-helmet';

import embedProviders, { TEmbedProviderMap, TProvider } from '../../config/embed-providers';

export interface IEmbedProps {
    providers?: TEmbedProviderMap;
    useProviders: TProvider[];
}

export default function Embed({ providers = embedProviders, useProviders }: IEmbedProps) {
    const [isReady, setIsReady] = useState(false);

    const handleScriptInject = (_: any, addedTags: HelmetTags) => {
        if (!addedTags.scriptTags) return;
        addedTags.scriptTags.forEach((script) => {
            const scriptProvider = script.dataset.provider;
            const providers = Object.keys(embedProviders);
            if (!scriptProvider || !providers.includes(scriptProvider)) return;
            const onLoad = embedProviders[scriptProvider as TProvider].onLoad;
            if (!onLoad || typeof onLoad !== 'function') return;
            script.onload = onLoad;
        });
    };

    useEffect(() => {
        // just to be safe :)
        setTimeout(() => setIsReady(true), 0);
    }, []);

    if (!isReady) {
        return null;
    }

    return (
        <Helmet onChangeClientState={handleScriptInject}>
            {useProviders.map(
                (provider) =>
                    providers[provider] && (
                        <script
                            key={`embed-script-${provider}`}
                            src={providers[provider].src}
                            data-provider={provider}
                        />
                    )
            )}
        </Helmet>
    );
}
