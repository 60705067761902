import React from 'react';

import { list, paginationBox } from './posts-list.module.scss';
import { IPostCard } from '../../models/post.model';
import useMediaQuery from '../../hooks/use-media-query';

import Pagination from '../molecules/pagination';
import PostCard from '../molecules/post-card';

export interface IPostsListProps {
    className?: string;
    paginationKeys?: string[];
    posts: IPostCard[];
    titleTag?: React.ElementType;
    isSmallCard?: boolean;
}

const PostsList: React.FC<IPostsListProps> = ({
    className = '',
    paginationKeys = [],
    posts,
    titleTag,
    isSmallCard = false,
}) => {
    const isIPad = useMediaQuery(1025);

    return (
        <div className={className}>
            <ul className={list}>
                {posts.map((post) => {
                    return (
                        <li key={`list-item-${post.slug}`}>
                            <PostCard post={post} titleTag={titleTag} isSmall={isSmallCard} />
                        </li>
                    );
                })}
            </ul>

            <Pagination className={paginationBox} keys={paginationKeys} range={isIPad ? 2 : 3} />
        </div>
    );
};

export default PostsList;
